import {
  atom,
  apiClientAtom,
  removeCookie,
  setClinicAliasToCookie,
  setAccessTokenToCookie,
  setRefreshTokenToCookie,
} from 'shared'

import type { Spread, Staff } from 'shared'

export const staffAtom = atom<Spread<Staff> | undefined>(undefined)

export const loginWithIdAndPasswordAtom = atom(
  null,
  async (
    get,
    set,
    {
      clinic_alias,
      id,
      password,
    }: {
      id: string
      password: string
      clinic_alias: string
    },
  ) => {
    const { access, refresh, staff } = await get(
      apiClientAtom,
    ).login_staff.$post({
      body: {
        clinic_alias,
        id,
        password,
      },
    })

    if (!staff || !access || !refresh) {
      throw new Error('ログインに失敗しました')
    }

    set(staffAtom, {
      ...staff,
    })

    setAccessTokenToCookie(access)
    setRefreshTokenToCookie(refresh)
    setClinicAliasToCookie(clinic_alias)

    return access
  },
)

export const loginAtom = atom(
  null,
  async (
    get,
    set,
    {
      clinic_alias,
      token,
    }: {
      clinic_alias: string
      token: string
    },
  ) => {
    const { access, refresh, staff } = await get(
      apiClientAtom,
    ).login_staff.$post({
      body: {
        id: undefined,
        password: undefined,
        clinic_alias,
      },
      config: {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    })

    if (!staff || !access || !refresh) {
      throw new Error('ログインに失敗しました')
    }

    set(staffAtom, staff)
    setAccessTokenToCookie(access)
    setRefreshTokenToCookie(refresh)
    setClinicAliasToCookie(clinic_alias)

    return staff
  },
)

export const logoutAtom = atom(null, (_, set) => {
  set(staffAtom, undefined)
  removeCookie('token')
  removeCookie('refresh_token')
})
