'use client'
import { LoadingButton } from '@mui/lab'
import { Box, Typography } from '@mui/material'
import { Center, FlexCol, RhfTextFieldGrid } from 'ui'

import { headerHeight } from '../../constants/size'
import { useSignin } from '../../hooks/useSignin'

export default function SignIn() {
  const { control, isLoading, submit } = useSignin()

  return (
    <Center height={`calc(100vh - ${headerHeight}px)`}>
      <FlexCol justifyContent="center" alignItems="center" pb={32}>
        <FlexCol gap={2}>
          <FlexCol alignItems="center" gap={2} p={2}>
            <Typography variant="h2" fontSize={24}>
              Atnd Staff
            </Typography>
          </FlexCol>
          <form>
            <FlexCol gap={1}>
              <RhfTextFieldGrid
                control={control}
                label="ID"
                placeholder="ID"
                name="id"
                autoComplete="username"
                dataCy="signin-id"
              />
              <Box>
                <RhfTextFieldGrid
                  type="password"
                  control={control}
                  label="パスワード"
                  placeholder="パスワード"
                  name="password"
                  autoComplete="current-password"
                  dataCy="signin-password"
                />
                <Typography variant="caption" color="#666">
                  英字・数字両方を含め8文字以上で入力してください。
                </Typography>
              </Box>
            </FlexCol>
          </form>
          <FlexCol gap={2}>
            <LoadingButton
              variant="contained"
              type="submit"
              color="primary"
              fullWidth
              onClick={submit}
              loading={isLoading}
              data-cy="signin-submit"
            >
              ログイン
            </LoadingButton>
          </FlexCol>
        </FlexCol>
      </FlexCol>
    </Center>
  )
}
