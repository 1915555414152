import { zodResolver } from '@hookform/resolvers/zod'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import {
  getLoginErrorMessage,
  getStaffSigninSchema,
  useSetAtom,
  useSnackbar,
  useAtomValue,
} from 'shared'

import { clinicAliasAtom } from '../stores/atoms/clinicAliasAtom'
import { loginWithIdAndPasswordAtom } from '../stores/atoms/staffAtom'

import type { StaffSigninDto } from 'shared'

const initialState: StaffSigninDto = {
  id: '',
  password: '',
}

export const useSignin = () => {
  const signinSchema = getStaffSigninSchema()
  const loginWithId = useSetAtom(loginWithIdAndPasswordAtom)

  const [isLoading, setIsLoading] = useState(false)

  const { control, handleSubmit } = useForm<StaffSigninDto>({
    mode: 'onSubmit',
    resolver: zodResolver(signinSchema),
    defaultValues: initialState,
  })

  const { openSnackbar } = useSnackbar()
  const clinic_alias = useAtomValue(clinicAliasAtom)

  const submit = handleSubmit(
    async (data) => {
      if (!clinic_alias) {
        openSnackbar('クリニックが指定されていません', 'error')
        return
      }

      try {
        setIsLoading(true)

        await loginWithId({ ...data, clinic_alias })
      } catch (e) {
        const errorMessage = getLoginErrorMessage(e)
        openSnackbar(errorMessage, 'error')
      } finally {
        setIsLoading(false)
      }
    },
    (error) => {
      console.error(error)
    },
  )

  return {
    control,
    submit,
    isLoading,
    setIsLoading,
  }
}

type UseSigninReturn = ReturnType<typeof useSignin>
export type SigninControl = UseSigninReturn['control']
export type SigninSubmit = UseSigninReturn['submit']
